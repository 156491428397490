import {
	IModifierDetails,
	IModifierSetDetails,
	IModifierSetRequestObjects,
	INestedModifierSetRequestObjects,
	INestedModifierRequestObjects,
	IOrderDetail,
	IOrderDetailItemRequestObjects,
	IOrderDetailItems,
	IOrderDetailRequestObject,
	IThreeDSecureDetails,
} from '../pages/home/models/orderDetailModels';
const customerName = localStorage.getItem('customerName');

export const constructPlaceOrderRequestBody = (orderDetailBefore: IOrderDetail, threeDSecure?: IThreeDSecureDetails | null): IOrderDetailRequestObject => {
	const orderDetailRequestObject: IOrderDetailRequestObject = { customerName: '', order: { items: [], guests: [] } };
	if (customerName) {
		orderDetailRequestObject.customerName = customerName;
	}

	const modSetsConversion = (itemDetailModsets: IModifierSetDetails[]): IModifierSetRequestObjects[] => {
		const itemDetailsModifierSets = itemDetailModsets.map((itemDetailModsetsItem: IModifierSetDetails) => {
			return {
				menuModSetName: itemDetailModsetsItem.menuModSetName,
				menuModSetGuid: itemDetailModsetsItem.menuModSetGuid,
				mods: itemDetailModsetsItem.mods && modsConversion(itemDetailModsetsItem.mods),
			};
		});
		return itemDetailsModifierSets;
	};

	const nestedModSetsConversion = (itemDetailModsets: IModifierSetDetails[]): INestedModifierSetRequestObjects[] => {
		const itemDetailsNestedModifierSets = itemDetailModsets.map(
			(itemDetailNestedModsetsItem: IModifierSetDetails) => {
				return {
					menuModSetName: itemDetailNestedModsetsItem.menuModSetName,
					menuModSetGuid: itemDetailNestedModsetsItem.menuModSetGuid,
					nestedMods: itemDetailNestedModsetsItem.mods && modsConversion(itemDetailNestedModsetsItem.mods),
				};
			}
		);
		return itemDetailsNestedModifierSets;
	};

	const modsConversion = (itemDetailMods: IModifierDetails[]): INestedModifierRequestObjects[] => {
		const itemDetailsModifiers = itemDetailMods.map((itemDetailModsItem: IModifierDetails) => {
			return {
				menuModGuid: itemDetailModsItem.menuModGuid,
				nestedModSets: itemDetailModsItem.modSets && nestedModSetsConversion(itemDetailModsItem.modSets),
				quantity: 1,
			};
		});
		return itemDetailsModifiers;
	};
	const items = orderDetailBefore.order.items.map((itemDetail: IOrderDetailItems): IOrderDetailItemRequestObjects => {
		return {
			menuItemGuid: itemDetail.menuItemGuid,
			quantity: itemDetail.quantity,
			specialRequest: itemDetail.specialRequest,
			modSets: itemDetail.modSets && modSetsConversion(itemDetail.modSets),
		};
	});
	if (items) {
		orderDetailRequestObject.order.guests = [{number: 1, items: items}];
	}
	if(threeDSecure){
		orderDetailRequestObject.threeDSecure = {...threeDSecure};
	}
	return orderDetailRequestObject;
};
