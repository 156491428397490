import React, { useContext, useEffect, useState } from 'react';
import PreAuthFooter from './PreAuthFooter';
import PreAuthHeader from './PreAuthHeader';
import PreAuthPageContext, { IPreAuthItemContext } from '../../Contexts/PreAuthPageContext/PreAuthPageContext';
import Spinner from '../../components/spinner/Spinner';
import styles from './PreAuthenticationDetails.module.scss';
import CreditCardI4GoIFrame from '../../components/creditCardI4GoIFrame/CreditCardI4GoIFrame';
import MenuItemsPageContext, { IMenuItemContext } from '../../Contexts/MenuItemsPageContext/MenuItemsPageContext';
import { I4goIdResponse, IAccessDetailsPostData } from './models/PreAuthModel';
import { AUTHORIZE_ERROR, INVALID_CARD_ERROR, TIME_OUT_ERROR } from './labels';
import ApiErrorModal from '../../components/errormodal/ApiErrorModal';
import { useHistory } from 'react-router-dom';
import OrderDetailPageContext, {
	IOrderDetailContext,
} from '../../Contexts/OrderDetailPageContext/OrderDetailPageContext';
import { constructPlaceOrderRequestBody } from '../../utils/orderUtils';
import * as labels from '../orderDetails/labels';
import ErrorModal from '../../components/errormodal/ErrorModal';
import ViewTicketDetailPageContext, {
	IViewTicketDetailPageContext,
} from '../../Contexts/ViewTicketPageContext/ViewTicketPageContext';
import LegalConsentModal from '../../components/legalConsentModal/LegalConsentModal';
import { CONSENT_MODAL_HEADER } from '../../constants/constants';
import { IThreeDSecureDetails } from '../home/models/orderDetailModels';

interface IProps {
	match: {
		params: {
			storeId: string;
		};
	};
}

const PreAuthDetails: React.FC<IProps> = (props) => {
	const { storeId } = props.match.params;
	const {
		tableName,
		getTableName,
		accessDetails,
		getAccessToken,
		accessDetailsError,
		setAccessDetailsError,
		getSessionId,
		sessionIdError,
		setSessionIdError,
	} = useContext(PreAuthPageContext) as IPreAuthItemContext;
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const history = useHistory();
	const tableRefId = localStorage.getItem('tableRef');
	const [i4goLoader, setI4goLoader] = useState(true);
	const [orderDetail, setOrderDetail] = useState(null);

	const { merchantData } = useContext(MenuItemsPageContext) as IMenuItemContext;
	const { postOrderDetail } = useContext(OrderDetailPageContext) as IOrderDetailContext;
	const { isCheckSessionValid } = useContext(ViewTicketDetailPageContext) as IViewTicketDetailPageContext;
	const [apiErrorMessage, setApiErrorMessage] = useState({ MESSAGE: '', TITLE: '', STATUS: 0 });
	const [i4GoUniqueId, setI4GoUniqueId] = useState<string>('');
	const [preAuthDetailsError, setPreAuthDetailsError] = useState({ MESSAGE: '', TITLE: '' });
	const [isConsentModalVisible, setIsConsentModalVisible] = useState<boolean>(false);
	const [threeDSecureDetails, setThreeDSecureDetails] = useState<IThreeDSecureDetails | null>(null);

	const TIP_PROPERTY_PREFIX = 'predefinedTipPercentage';
	const tips = merchantData?.tips;

	const defaultTipPercentageValue =
		tips?.preselectedTip &&
		tips.areAllowed &&
		Object.entries(tips).flatMap(([key, value]) => (key.includes(TIP_PROPERTY_PREFIX) ? [value / 100] : []))[
			tips.preselectedTip - 1
		];
	const storedSessionId = localStorage.getItem('storedSessionId');
	const existingSessionId = storedSessionId;

	const order = localStorage.getItem('orderDetail');

	useEffect(() => {
		if (!existingSessionId && merchantData?.preAuthAmount) {
			const itemInCart = localStorage.getItem('cartItems');
			let totalAmount = 0;
			if (itemInCart) {
				const { totalPrice } = JSON.parse(itemInCart);
				totalAmount = totalPrice;
			}

			const accessDetailsPostData: IAccessDetailsPostData = {
				email: `${localStorage.getItem('customerEmail')}`,
				tips: defaultTipPercentageValue ? Math.round((totalAmount * defaultTipPercentageValue) / 100) : 0,
				totalAmount: merchantData?.preAuthAmount,
			};
			getAccessToken(storeId, accessDetailsPostData);
			getTableName(storeId);
		}

		if (order) {
			setOrderDetail(JSON.parse(order));
			localStorage.setItem('cartItems', JSON.stringify(JSON.parse(order).order));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (i4GoUniqueId) {
			const i4goIdData = {
				i4goUniqueId: i4GoUniqueId,
			};
			setI4goLoader(true);
			getSessionId(storeId, i4goIdData);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [i4GoUniqueId]);

	useEffect(() => {
		if (isCheckSessionValid) {
			placeOrder();
		} else if ((tableName && accessDetails.accessBlock) || accessDetailsError) {
			setIsLoading(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCheckSessionValid, orderDetail, tableName, accessDetails, accessDetailsError]);

	useEffect(() => {
		if (sessionIdError) {
			setI4goLoader(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sessionIdError]);

	useEffect(() => {
		if (accessDetailsError) {
			setPreAuthDetailsError(AUTHORIZE_ERROR);
		}
		if (sessionIdError) {
			setPreAuthDetailsError(INVALID_CARD_ERROR);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accessDetailsError, sessionIdError]);

	const handleErrorClick = () => {
		setAccessDetailsError('');
		setSessionIdError('');
		setPreAuthDetailsError({ MESSAGE: '', TITLE: '' });
		history.push(`/${storeId}?tableRef=${tableRefId}`);
	};

	const placeOrder = async () => {
		if (orderDetail) {
			const orderDetailRequestBody = constructPlaceOrderRequestBody(orderDetail, threeDSecureDetails);
			const orderDetailResponseObject = await postOrderDetail(storeId, orderDetailRequestBody);
			if (orderDetailResponseObject?.error && [500, 400, 404].includes(orderDetailResponseObject.error.status)) {
				const apiError = labels.PRE_AUTH_API_ERRORS;
				localStorage.removeItem('storedSessionId');
				if (orderDetailResponseObject.error.status === 500) {
					setApiErrorMessage(apiError[2]);
				} else {
					setPreAuthDetailsError(INVALID_CARD_ERROR);
				}
			} else {
				const selectedPreviousTicketRef = localStorage.getItem('selectedPreviousTicketRef');
				if (!selectedPreviousTicketRef && orderDetailResponseObject.order?.ticketGuid) {
					localStorage.setItem('selectedPreviousTicketRef', orderDetailResponseObject.order.ticketGuid);
				}
				localStorage.removeItem('cartItems');
				setI4goLoader(false);
				history.push(`/${storeId}/OrderConfirmView`);
			}
		}
	};

	const onSuccess = (data: I4goIdResponse) => {
		if (data.i4go_uniqueid && data.i4go_response === 'SUCCESS') {
			if (data.i4go_cardholdername) {
				localStorage.setItem('customerName', data.i4go_cardholdername);
			}
			const threeDSecureData = data?.paymentAPIResults?.['3DS_STANDALONE']?.threeDSecure;
			if (threeDSecureData) {
				setThreeDSecureDetails(threeDSecureData);
			}
			setI4GoUniqueId(data?.i4go_uniqueid);
		}
	};

	const onFailure = (data: I4goIdResponse) => {
		if (data.i4go_response === 'FAILURE') {
			if (data?.i4go_responsecode === '-101') {
				setPreAuthDetailsError(TIME_OUT_ERROR);
			} else {
				setSessionIdError(data?.i4go_responsetext);
			}
		}
	};

	if (preAuthDetailsError.MESSAGE?.length > 0) {
		return (
			<ApiErrorModal
				handleDismiss={handleErrorClick}
				typeOfError={preAuthDetailsError.TITLE}
				errorMsg={preAuthDetailsError.MESSAGE}
				actionType={'Retry'}
				isBackIconEnabled={true}
			/>
		);
	}

	const handleDismiss = () => {
		setIsConsentModalVisible(false);
		localStorage.removeItem('customerEmail');
		history.push({
			pathname: `/${storeId}/orderDetail`,
			state: {
				storeId,
			},
		});
	};

	const handleConsent = () => {
		setIsConsentModalVisible(false);
	};

	return (
		<>
			{isLoading && !accessDetails.accessBlock ? (
				<Spinner />
			) : apiErrorMessage.MESSAGE?.length > 0 ? (
				<ErrorModal
					handleDismiss={handleErrorClick}
					typeOfError={apiErrorMessage.TITLE}
					errorMsg={apiErrorMessage.MESSAGE}
					showButton={true}
				/>
			) : (
				<>
					{merchantData && (
						<LegalConsentModal
							modalHeader={CONSENT_MODAL_HEADER}
							showModal={isConsentModalVisible}
							consentMessage={merchantData.preAuthLegalAgreementMessage}
							handleDismiss={handleDismiss}
							handleConsent={handleConsent}
						/>
					)}
					<div className={styles.preAuthContainer}>
						<PreAuthHeader storeId={storeId} tableName={tableName} />
						<CreditCardI4GoIFrame
							onSuccess={onSuccess}
							onFailure={onFailure}
							accessDetails={accessDetails}
							i4goLoader={i4goLoader}
							setI4goLoader={setI4goLoader}
							setIsConsentModalVisible={setIsConsentModalVisible}
						/>
						<PreAuthFooter defaultTipPercentageValue={defaultTipPercentageValue} />
					</div>
				</>
			)}
		</>
	);
};

export default PreAuthDetails;
